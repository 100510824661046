import { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import loginLogo from "../assets/images/loginLogo.svg";

import CustomPasswordInput from "../components/CustomPasswordInput";
import { signin } from "../redux/user";
import {
  login,
  getDataForVerification,
  updateVerify,
  getUserDetail,
} from "../api/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getDeviceToken, onMessageListener } from "../utils/firebase";
import { changeLovedOne } from "../redux/lovedOne";

import { Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import { PhoneComponent } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
import { useErrorHandler } from "react-error-boundary";
import InstallPWA from "components/InstallPWA";
import { setProfile } from "utils/localStorage";

export default function Signin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector((state) => state.user);

  const [userData, setUserData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const [inputError, setInputError] = useState({
    msg: "",
    isError: false,
    serverError: "",
  });
  const [device_token, setDeviceToken] = useState("");
  const [login_cred, setLoginCred] = useState({
    phone: "",
    password: "",
    remember_me: false,
  });
  const handleError = useErrorHandler();
  const cookie_name = "bpp_cred";
  useEffect(async () => {
    console.log("line no 56")
    let token = await getDeviceToken();
    setDeviceToken(token ? token : "");
    if (document.cookie.indexOf(cookie_name) > -1) {
      console.log(
        "document.cookie.split(cookie_name)",
        JSON.parse(document.cookie.split("=")[1])
      );
      setLoginCred(JSON.parse(document.cookie.split("=")[1]));
      // return document.cookie.split(cookie_name)[1].split("; ")[0].substr(1);
    }
  }, []);

  useEffect(() => {
    if (params.string) {
      setLoading(true);

      getDataForVerification(params.string)
        .then((response) => {
          const { statusCode, data } = response;
          console.log("response", response);
          if (response.statusCode == 200) {
            setUserData(data);
          } else {
            return handleError(response.error);
          }
        })
        .catch((error) => {
          console.log("error catch", error);
          setUserData({});
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (user.isAuthorized) {
        navigate("/MenuScreen");
      }
    }
  }, []);

  useEffect(() => {
    if (Object.keys(userData).length && user.isAuthorized) {
      if (userData.mobile == user.profile.mobile) {
        updateUserVerify({ string: params.string });
      } else {
        toast("Verification mobile is different with your logged in mobile", {
          type: "error",
        });
        navigate("/MenuScreen");
      }
    }
  }, [userData]);

  const handleSubmit = (data) => {
    data.string = params ? params.string : "";
    data.mobile = data.phone;
    //alert(name);
    if (data.remember_me) {
      // save the credentials in cookie
      let cookie_data = {
        phone: data.phone,
        password: data.password,
        remember_me: data.remember_me,
      };
      let cookieString = JSON.stringify(cookie_data);
      document.cookie = `${cookie_name}=${cookieString}; path=/`;
    } else {
      document.cookie = cookie_name + '="";-1; path=/';
    }
    login(data)
      .then(async (res) => {
        if (params.string) {
          updateUserVerify(data, res);
        } else {
          let token = await getDeviceToken();
          setDeviceToken(token ? token : "");
          dispatch(signin());
          console.log(res.data.data, "res::::::::::");
          let lovedOneList = res.data.data.lovedOneList;
          console.log("lovedOneList", lovedOneList);
          if (lovedOneList.length == 0) {
            console.log("lovedOneList.length", lovedOneList.length);
            navigate("/LovedOneList", {
              state: { is_read: res.data.data.read_pwa_popup },
            });
          } else {
            console.log("lovedOneList.length else part", lovedOneList.length);
            dispatch(changeLovedOne(lovedOneList[0]));
            navigate("/MenuScreen", {
              state: { is_read: res.data.data.read_pwa_popup },
            });
            // window.location = "/MenuScreen";
          }
        }
      })
      .catch((error) => { });
  };

  const updateUserVerify = async (data, res = false) => {
    updateVerify(data)
      .then(() => {
        if (res) {
          dispatch(signin());
          navigate("/MenuScreen", {
            // state: { string: params?.string },
          });
        }

        getUserDetail()
          .then((res) => {
            // console.log("res asdasdadsadasdsadsad:>> ", res);
            setProfile(res);
          })
          .catch((error) => {
            console.log("error in app.js in api", error.response);
            return error.response.data.statusCode != 400
              ? localStorage.removeItem("bpp_jwt_token")
              : "";
          });
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required(messages.required.password),
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      })
      .test(
        "is-confirm",
        (d) => `Number Doesn't Match`,
        (value) => (params.string ? userData.mobile === value : true)
      ),
  });
  const formik = useFormik({
    initialValues: {
      ...login_cred,
      device_token,
      role: "fm",
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      const castValues = LoginSchema.cast(values);
      handleSubmit(castValues);
    },
    enableReinitialize: true,
  });
  // const [is_pwa, setIsPWA] = usePWA();

  if (isLoading) {
    return <Spinner animation="border" variant="primary" />;
  }
  return (
    <>
      {/* <InstallPWA /> */}
      <div className="mainPage loginPage">
        {params.string ? (
          <div className="customerimg">
            <img src={userData?.photo} alt="" />
          </div>
        ) : (
          ""
        )}

        <div className="contentBody fulogin">
          {params.string && userData ? (
            <h1 className="welcome">
              <span className="fs36">Welcome {userData.invitee_name},</span>
              Sign in to join {userData?.first_name}'s secure Family & Friends
              network
              {/* <b>Jan</b> */}
            </h1>
          ) : (
            <div>
              <div className="loginLogo loginfix">
                <img src={loginLogo} alt="" />
              </div>

              <div className="contentBody">
                <h1>
                  <span>Welcome.</span>
                  Sign in to proceed.
                </h1>
              </div>
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <div className="mb-3">
                  <PhoneComponent
                    formik={formik}
                    name={"phone"}
                    label={"Mobile No"}
                    placeholder={"Mobile *"}
                    icon={"phone"}
                    type="text"
                    required
                  />
                </div>
                <div className="mb-1">
                  <CustomPasswordInput
                    formik={formik}
                    name="password"
                    label="Password"
                    required
                  />
                </div>
              </div>

              <span style={{ color: "red" }}>{inputError.serverError}</span>
              <div className="col-lg-12  mb-4">
                <input
                  type="checkbox"
                  id="remember_me"
                  name="remember_me"
                  className="form-check-input"
                  onChange={formik.handleChange}
                  checked={formik.values.remember_me}
                  value={formik.values.remember_me}
                />{" "}
                Remember Me
                <NavLink to="/ForgotPass" className="aNormalT">
                  Forgot Password?
                </NavLink>
                <div className="">
                  <small className="fs12">
                    <span className="pe-2">
                      <b>*=Required fields</b>
                    </span>
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button type="submit" className="btn btn-primary w-100">
                  Sign in
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="bigpurplebottom text-center ">
          <a href="/">
            <img src={bigPurplePhoneLogo} alt="" title="" />
          </a>
        </div>
      </div>
    </>
  );
}
