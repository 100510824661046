import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import SplashScreen from "./screens/SplashScreen";
// import Registration from "./screens/Registration";

import Signin from "./screens/Signin";

import NotFound from "./screens/NotFound";

import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getDeviceToken, onMessageListener } from "./utils/firebase";
import Notification from "./components/Notification";
import { getUserDetail } from "api/api";
import { ErrorBoundary } from "react-error-boundary";
import AuthRoutes from "Routes/AuthRoutes";
import packageJson from "../package.json";
import withClearCache from "./ClearCache";
import dayjs from "dayjs";
// import ForgotPass from "screens/ForgotPass";
// import OtpVerify from "screens/OtpVerify";
// import ResetPassword from "screens/ResetPassword";
import UnAuthRoutes from "Routes/UnAuthRoutes";
import ContactUs from "screens/ContactUs";
// import Faqs from "screens/Faqs";
import { setProfile } from "utils/localStorage";
import ErrorFallback from "components/Error";
import VideoCall from "screens/VideoCall";
import ShortUrl from "screens/ShortUrl";
import MaintenancePage from "screens/MaintenancePage";

const queryClient = new QueryClient();
const ClearCacheComponent = withClearCache(MainApp);

const getBuildDate = (epoch) => {
  const buildDate = dayjs(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};
export default function App() {
  return <ClearCacheComponent />;
}
function MainApp() {
  useEffect(() => {
    getDeviceToken();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("bpp_jwt_token")) {
      getUserDetail()
        .then((res) => {
          setProfile(res);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? localStorage.removeItem("bpp_jwt_token")
            : "";
        });
    }
  }, [localStorage.getItem("bpp_jwt_token")]);

  navigator.serviceWorker.addEventListener("message", (event) => {
    console.log("event :>> ", event.data);
    if (event.data.type == "missedVideoCall") {
      window.location.href = "/MenuScreen";
    }
    // Add handler here
  });

  const WrapError = ({ children }) => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        {children}{" "}
      </ErrorBoundary>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ToastContainer theme={"colored"} hideProgressBar autoClose={2000} />

        <Router>
          <Notification />

          <Routes>
            {/* Public Screens */}
            <Route path="/" element={<SplashScreen />} index />
            <Route path="/videoCall/:short_url" element={<ShortUrl />} />

            {/* <Route
              path="/Registration/:string"
              element={
                <Unauthorized>
                  <Registration />
                </Unauthorized>
              }
            /> */}
            {/* <Route path="/Signin" element={<Signin />} />
            <Route path="/ForgotPass" element={<ForgotPass />} />
            <Route path="/OtpVerify" element={<OtpVerify />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />*/}

            <Route
              path="/Signin/:string"
              element={
                <WrapError>
                  <Signin />
                  {/* <MaintenancePage /> */}
                </WrapError>
              }
            />
            <Route
              path="/VideoCall/:role/:caller_id/:room_id"
              element={
                <WrapError>
                  <VideoCall />
                </WrapError>
              }
            />
            <Route path="/contact-us" element={<ContactUs />} />
            {/* <Route path="/faqs" element={<Faqs />} /> */}
            {AuthRoutes()}
            {UnAuthRoutes()}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
}
